import React, { useState } from "react";
import { notify } from "../utilComponents/Notification";
import { requestWorkflowSheet, checkStatus } from "../apis/workflowsApi";
import { useSelector } from "react-redux";

const Workflows = () => {

    const [workflowsList, setWorkflowsList] = useState(['']);
    const [isProcessing, setIsProcessing] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [status, setStatus] = useState('');

    const { email } = useSelector((state) => state.auth.user);

    const handleWorkflowNameChange = (index, event) => {
        const newWorkflowList = [...workflowsList];
        newWorkflowList[index] = event.target.value;
        setWorkflowsList(newWorkflowList);
    }

    const handleAddInput = () => {
        setWorkflowsList([...workflowsList, '']);
    }

    const handleRemoveInput = (index) => {
        const newWorkflowList = workflowsList.filter((_, i) => i !== index);
        setWorkflowsList(newWorkflowList);
    }

    const handleFormSubmission = async (event) => {
        notify('info', 'Creating Sheet', 'This process can take upto 15 mins');
        event.preventDefault();
        setIsProcessing(true);
        setStatus('Processing...');
        setDownloadLink('');

        try {
            const data = await requestWorkflowSheet(workflowsList, email);
            const { jobId } = data;

            const interval = setInterval(async () => {
                try {
                    const statusResponse = await checkStatus(jobId);
                    const { status, sheetUrl } = statusResponse;

                    if (status === 'completed') {
                        clearInterval(interval);
                        setIsProcessing(false);
                        setStatus('Processing complete');
                        setDownloadLink(sheetUrl);
                    } else if (status === 'failed') {
                        notify('error', 'Error', 'Error in fetching data. Try after sometime');
                        clearInterval(interval);
                        setIsProcessing(false);
                        setStatus('Processing failed');
                    }
                } catch (error) {
                    notify('error', 'Error', error.message);
                    clearInterval(interval);
                    setIsProcessing(false);
                    setStatus('Processing failed');
                }

            }, 10000);
        }
        catch (error) {
            notify('error', 'Error', error.message);
            console.error('Error processing data:', error);
            setIsProcessing(false);
            setStatus('Error processing data');
        }
    }

    return (
        <div className="workflows-container">
            <div className="data-processing-container">
                <h1 className="workflows-h1">Workflow data</h1>
                <form onSubmit={handleFormSubmission} className="workflows-form">
                    {workflowsList.map((workflowName, index) => (
                        <div key={index} className="input-group">
                            <input type="text"
                                value={workflowName}
                                onChange={(event) => handleWorkflowNameChange(index, event)}
                                className="input-field"
                            />
                            {index > 0 && (
                                <button type="button" onClick={() => handleRemoveInput(index)}
                                    className="remove-button">
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" onClick={handleAddInput} className="add-button">+</button>
                    <button type="submit" disabled={isProcessing} className="submit-button">Submit</button>
                </form>
                {isProcessing && <p className="status-message">{status}</p>}
                {downloadLink && (
                    <a href={downloadLink} download="Workflows.xlsx" className="download-link">
                        Download Result
                    </a>
                )}
            </div>
        </div>
    );
};

export default Workflows;