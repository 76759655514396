import React from 'react';

const IoOtherDetails = ({ ioStats }) => {
    const otherDetails = {
        "Avg Look Days": ioStats["Avg Look Days"],
        "Avg Book Days": ioStats['Avg Book Days'],
        "Autobook Total": ioStats['Autobook Total'],
        "Autobook %": ioStats['Autobook %'],
        "Pct Repeat All": ioStats['Pct Repeat All'],
        "Pct Repeat": ioStats['Pct Repeat'],
        "Adwords Events": ioStats['Adwords Events'],
        "Adwords Customers": ioStats['Adword Customers'],
        "Adwords Total": ioStats['Adwords Total'],
        "Facebook Customers": ioStats['Facebook Customers'],
        "Facebook Events": ioStats['Facebook Events'],
        "Facebook Total": ioStats['Facebook Total'],
        "Survey: Would Rent Again": ioStats['Survey: Would Rent Again'],
        "Survey: Booking Process": ioStats['Survey: Booking Process'],
        "Survey: Customer Service": ioStats['Survey: Customer Service'],
        "Survey: Friendliness": ioStats['Survey: Friendliness'],
        "Survey: Competence": ioStats['Survey: Competence'],
        "Survey: Promptness": ioStats['Survey: Promptness']
    };

    return (
        // <div className="io-other-details-container">
            <div className="io-other-details-content">
                <ul>
                    {Object.keys(otherDetails).map((key, index) => (
                        <li key={index}>
                            <strong>{key}:</strong> {otherDetails[key]}
                        </li>
                    ))}
                </ul>
            </div>
        // </div>
    );
};

export default IoOtherDetails;
