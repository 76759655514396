import React, { useState, useEffect } from 'react';
import { fetchYearlyFunnelsOfLocation } from '../apis/funnelCardApi';
import { notify } from '../utilComponents/Notification';

const FunnelCard = ({locationId}) => {

    const [funnelData, setFunnelData] = useState([]);

    useEffect(() => {
        const getContactsData = async () => {
            try {
                const funnelMonthlyCount = await fetchYearlyFunnelsOfLocation(locationId);
                setFunnelData(funnelMonthlyCount);
            } catch (err) {
                notify('error', 'Funnel Data', err.message);
            }
        };
        
        getContactsData();
    }, []);

    return (
        <div className="workflow-card-content">
            <div className="workflow-card-summary">
                Number of active funnels in each month
            </div>
            <div className='workflow-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Funnel Count</th>
                            <th>Funnel Page Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funnelData.map((workflow) => (
                            <tr key={workflow.month}>
                                <td>{workflow.month}</td>
                                <td>{workflow.funnelCount}</td>
                                <td>{workflow.funnelPageCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FunnelCard;
