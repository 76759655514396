import axios from "axios"
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const getResponseTime = async (startDate, endDate, locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    
    try {
        const { data } = await axios.get(BASE_URL + `/dashboard/location/response-time?locationId=${locationId}&fromDate=${startDate}&toDate=${endDate}`, {headers: header});
        return data;
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError('Cannot fetch response time data', 500);
    }
}