import React, { useState, useEffect } from 'react';
import { fetchYearlyContactsOfLocation } from '../apis/contactCardApi';
import { notify } from '../utilComponents/Notification';

const ContactCard = ({ locationId }) => {
    const [contacts, setContacts] = useState([{
        "month": "N/A",
        "count": "N/A"
    }]);

    useEffect(() => {
        const getContactsData = async () => {
            try {
                const contactMonthlyCount = await fetchYearlyContactsOfLocation(locationId); // Fetch contact details from your API
                setContacts(contactMonthlyCount);
            } catch (err) {
                notify('error', 'Contacts Data', err.message);
            }
        };
        getContactsData();
    }, []);

    return (
        <div className="workflow-card-content">
            <div className="workflow-card-summary">
                Number of contacts each month
            </div>
            <div className='workflow-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Countact count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact) => (
                            <tr key={contact.month}>
                                <td>{contact.month}</td>
                                <td>{contact.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ContactCard;
