import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

const monthName = ["NaN", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const fetchYearlySocialMediaDataOfLocation = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(BASE_URL + `/dashboard/location/socialmedia-data/yearly?locationId=${locationId}&year=2024`, { headers: header });
        return formatSocialMediatData(data['Social Media Data']);
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch social media data");
    }
}

const formatSocialMediatData = (data) => {
    const socialMediaData = {};
    const postCount = [];
    data.forEach(element => {
        const month = monthName[element.month];
        const count = element.postCount;
        postCount.push({
            "month": month,
            "count": count
        });
    });
    data.forEach(element => {
        if (element.linkedin !== "Not linked")
            socialMediaData.linkedin = true;
        if (element.facebook !== "Not linked")
            socialMediaData.facebook = true;
        if (element.tiktok !== "Not linked")
            socialMediaData.tiktok = true;
        if (element.twitter !== "Not linked")
            socialMediaData.twitter = true;
    });
    socialMediaData.postCount = postCount;
    return socialMediaData;
}
