import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

const monthName = ["NaN", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const fetchYearlyFunnelsOfLocation = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try{
        const { data } = await axios.get(BASE_URL + `/dashboard/location/funnel-data/yearly?locationId=${locationId}&year=2024`, {headers: header});
        return formateFunnelData(data.FunnelData);
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch Funnel Data");
    }
}

const formateFunnelData = (data) => { 
    const funnelData = [];
    data.forEach(element => {
        const month = monthName[element.month];
        funnelData.push({
            "month": month,
            "funnelPageCount": element.funnelPageCount,
            "funnelCount": element.funnelCount
        });
    });
    return funnelData;
}
