import React, { useEffect, useState } from 'react';
import { fetchYearlyWorkflowCount } from '../apis/WorkflowCardApi';
import { notify } from '../utilComponents/Notification';

const WorkflowCard = ({ locationId }) => {

    const [workflowData, setWorkflowData] = useState([{
        "month": "N/A",
        "count": "N/A"
    }]);

    useEffect(() => {
        const fetchWorkflowData = async () => {
            try {
                const data = await fetchYearlyWorkflowCount(locationId);
                setWorkflowData(data);
            } catch (error) {
                notify('error', 'Workflow Data', error.message);
            }
        }

        fetchWorkflowData();
    }, [])

    return (
        <div className="workflow-card-content">
            <div className="workflow-card-summary">
                Number of active workflows each month
            </div>
            <div className='workflow-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Active Workflows</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workflowData.map((workflow) => (
                            <tr key={workflow.month}>
                                <td>{workflow.month}</td>
                                <td>{workflow.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WorkflowCard;
