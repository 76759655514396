import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const IoLeadsCount = ({ ioStats }) => {
    const totalLeads = ioStats['Total Leads'];
    const totalEvents = ioStats['Total Events'];
    const conversionRate = ioStats['Conversion Rate'];

    console.log(ioStats);

    const data = {
        labels: ['Leads', 'Events'],
        datasets: [
            {
                data: [totalLeads - totalEvents, totalEvents],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                enabled: true,
            },
        },
        cutout: '70%', // For the donut hole
        responsive: true,
    };

    return (
        <div className='ioLeadsCount-container'>
            {/* Left side with statistics */}
            <div className='ioLeadsCount-display-data'>
                <p className='ioLeadsCount-data-objects'><strong>Total Sales:</strong> {ioStats['Total Sales']}</p>
                <p className='ioLeadsCount-data-objects'><strong>Avg Event:</strong> {ioStats['Avg Event']}</p>
                <p className='ioLeadsCount-data-objects'><strong>% Discounts:</strong> {ioStats['% Discounts']}</p>
                <p className='ioLeadsCount-data-objects'><strong>Rentals/Event:</strong> {ioStats['Avg Rentals/Event']}</p>
                <p className='ioLeadsCount-data-objects'><strong>Rental $/Event:</strong> {ioStats['Avg Rental $/Event']}</p>
            </div>

            {/* Right side with the pie chart */}
            <div className='ioLeadsCount-pie-chart'>
                <Pie data={data} options={options} />
                <div className='ioLeadsCount-conversion-rate'>
                    {conversionRate} <p>Conversion</p>
                </div>
            </div>
        </div>
    );
};

export default IoLeadsCount;
