import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const FilterComponent = ({ applyFilters, handleDownload }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('All');
    const [saasStatus, setSaasStatus] = useState('All');
    const [selectedSoftware, setSelectedSoftware] = useState('All');
    const bookingSoftwareOptions = [
        { value: 'All', label: 'All' },
        { value: 'IO', label: 'IO' },
        { value: 'ERS', label: 'ERS' },
        { value: 'Event Hawk', label: 'Event Hawk' },
        { value: 'Other', label: 'Other' }
    ];
    const availableStatus = [
        { value: 'All', label: 'All' },
        { value: 'Active', label: 'Active' },
        { value: 'Deleted', label: 'Deleted' }
    ]
    const availableSaasStatus = [
        { value: "All", label: 'All' },
        { value: 'activated', label: 'Active' },
        { value: 'not_activated', label: 'Disable' },
        { value: 'none', label: 'None' }
    ];

    const hadleStatusChange = (e) => {
        const value = e.target.value;
        setStatus(value);
        applyFilters(startDate, endDate, selectedSoftware, value, saasStatus);
    }

    const hadleSaasStatusChange = (e) => {
        const value = e.target.value;
        setSaasStatus(value);
        applyFilters(startDate, endDate, selectedSoftware, status, value);
    }

    const handleBookingSoftwareChange = (e) => {
        const value = e.target.value;
        setSelectedSoftware(value);
        applyFilters(startDate, endDate, value, status, saasStatus);
    };

    const handleStartDateChange = (date) => {
        console.log(date);
        setStartDate(date);
        applyFilters(date, endDate, selectedSoftware, status, saasStatus);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
        applyFilters(startDate, date, selectedSoftware, status, saasStatus);
    }

    const clearFilters = () => {
        setSelectedSoftware('All');
        setStatus('All');
        setSaasStatus('All');
        setStartDate();
        setEndDate();
        applyFilters(null, null, null, null, null);
    };

    return (
        <div className="filter-container">
            <div className="filter-item">
                <span className="filter-label">Booking Software</span>
                <select class="booking-software-select" value={selectedSoftware} onChange={handleBookingSoftwareChange}>
                    {bookingSoftwareOptions.map((software, index) => (
                        <option key={index} value={software.value}>
                            {software.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="filter-item">
                <span className="filter-label">Created Date</span>
                <div className="date-picker-container">
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        isClearable
                        todayButton="Today"
                        placeholderText="From"
                        className="date-picker"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        isClearable
                        todayButton="Today"
                        placeholderText="To"
                        className="date-picker"
                    />
                </div>
            </div>

            <div className="filter-item">
                <span className="filter-label">Account Status</span>
                <select class="booking-software-select" value={status} onChange={hadleStatusChange}>
                    {availableStatus.map((status, index) => (
                        <option key={index} value={status.value}>
                            {status.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="filter-item">
                <span className="filter-label">SaaS Status</span>
                <select class="booking-software-select" value={saasStatus} onChange={hadleSaasStatusChange}>
                    {availableSaasStatus.map((status, index) => (
                        <option key={index} value={status.value}>
                            {status.label}
                        </option>
                    ))}
                </select>
            </div>
            <p className="filter-label clear-btn" onClick={clearFilters}>Clear</p>

            {/* Download Button */}
            <div className="filter-item download-btn-container">
                <button className="download-btn" onClick={handleDownload}>
                    <FontAwesomeIcon icon={faDownload} size="lg" />
                </button>
            </div>
        </div>
    );
};

export default FilterComponent;
