import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const getAllLocations = async () => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        const { data } = await axios.get(BASE_URL + "/dashboard/location/all-business", { headers: header });
        return data;
    } catch (error) {
        console.log("ERROR: ", error);
        return false;
    }

}