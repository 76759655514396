import React, { useState, useEffect, useRef } from 'react';
import { MDBDataTable } from "mdbreact";
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import FilterComponent from './FilterComponent';
import { getAllLocations } from '../apis/homePageApi';
import * as XLSX from 'xlsx';

const Homepage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        'entryNumber',
        'businessName',
        'businessEmail',
        'bookingSoftware',
        'action'
    ]);

    // fetch and set location data
    async function fetchLocationData() {
        const data = await getAllLocations();

        const handleButtonClick = (row) => {
            navigate(`/location/${row.LocationId}`);
        };

        const newRows = data.Businesses.map((row, index) => ({
            ...row,
            entryNumber: index + 1,
            action: (
                <button className='btn-view' onClick={() => handleButtonClick(row)}>View</button>
            ),
            status: row.active ? "Active" : "Deleted"
        }));

        setRows(newRows);
        setFilteredRows(newRows);
        setLoading(false);
    }

    useEffect(() => {
        fetchLocationData();
    }, []);

    const handleFilterApply = (startDate, endDate, selectedSoftware, selectedStatus, selectedSaasStatus) => {
        let filteredData = [...rows];

        console.log(startDate, endDate, selectedSoftware, selectedStatus);

        // Apply the date range filter
        if (startDate) {
            filteredData = filteredData.filter((row) => {
                const accountCreationDate = new Date(row.accountCreationDate);
                return accountCreationDate >= startDate;
            });
        }

        if (endDate) {
            filteredData = filteredData.filter((row) => {
                const accountCreationDate = new Date(row.accountCreationDate);
                return accountCreationDate <= endDate;
            });
        }

        // Apply the booking software filter
        if (selectedSoftware && selectedSoftware !== 'All') {
            filteredData = filteredData.filter((row) => {
                return row.bookingSoftware === selectedSoftware;
            });
        }

        // Apply account status filter
        if (selectedStatus && selectedStatus !== 'All') {
            filteredData = filteredData.filter((row) => {
                return row.status === selectedStatus;
            });
        }

        // Apply SaaS status filter
        if (selectedSaasStatus && selectedSaasStatus !== 'All') {
            if (selectedSaasStatus === 'none')
                selectedSaasStatus = null;

            filteredData = filteredData.filter((row) => {
                return row.saas === selectedSaasStatus;
            });
        }

        setFilteredRows(filteredData);
    };

    const allColumns = [
        { label: '#', field: 'entryNumber', sort: false },
        { label: 'LocationId', field: 'LocationId', sort: false },
        { label: "Business Name", field: "businessName", sort: false, width: 150 },
        { label: "Email", field: "businessEmail", sort: false, width: 150 },
        { label: "Phone", field: "businessPhone", sort: false },
        { label: "City", field: "city", width: 150 },
        { label: "Account Status", field: "status", width: 150 },
        { label: "SaaS Status", field: "saas", width: 150 },
        { label: "Stripe ID", field: "stripCustomerId", width: 150 },
        { label: "Booking Software", field: "bookingSoftware", width: 150, sort: false },
        { label: "Account Creation Date", field: "accountCreationDate", width: 150, sort: false },
        { label: "Last Update", field: "updatedAt", width: 150 },
        { label: "Action", field: "action", sort: false, },
    ];

    const fixedColumns = [
        'entryNumber',
        'businessName',
        'businessEmail',
        'bookingSoftware',
        'action'
    ];

    const handleColumnChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedColumns([...selectedColumns, value]);
        } else {
            setSelectedColumns(selectedColumns.filter(column => column !== value));
        }
    };

    const displayedColumns = allColumns.filter(column => selectedColumns.includes(column.field));

    const handleDownload = () => {
        const fieldsToExtract = ['LocationId', 'accountCreationDate', 'businessName', 'businessPhone', 'businessEmail', 'businessWebsite ', 'bookingSoftware', 'saas', 'status', 'stripCustomerId'];

        const sheetData = filteredRows.map(item =>
            Object.keys(item)
                .filter(key => fieldsToExtract.includes(key))
                .reduce((obj, key) => {
                    obj[key] = item[key];
                    return obj;
                }, {})
        );

        const worksheet = XLSX.utils.json_to_sheet(sheetData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, 'exported_data.xlsx');
    }

    return <>
        {
            loading ? (
                <div className='loadingBar'>
                    <ReactLoading type={"bars"} color={"#03fc4e"} height={100} width={100} />
                </div>
            ) : (
                <div className='homepage-page-container'>

                    {/* Filter section */}
                    <FilterComponent
                        applyFilters={handleFilterApply} handleDownload={handleDownload}
                    />

                    {/* Table section */}
                    <div className='location-table-container'>
                        <div className='table-header-container'>
                            <h2 className='table-header'>All Locations</h2>
                            <div className='column-selection'>
                                <div className="dropdown">
                                    <button className="dropbtn">
                                        Columns <span className="arrow-down">▼</span>
                                    </button>
                                    <div className="dropdown-content">
                                        {allColumns.map((column, index) => (
                                            <div key={index}>
                                                <input
                                                    type="checkbox"
                                                    value={column.field}
                                                    checked={selectedColumns.includes(column.field)}
                                                    onChange={handleColumnChange}
                                                    disabled={fixedColumns.includes(column.field)}
                                                />
                                                <label>{column.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='custom-data-table'>
                            <MDBDataTable
                                striped
                                bordered
                                hover
                                fixed
                                data={{ columns: displayedColumns, rows: filteredRows }}
                                className="table-responsive"
                            />
                        </div>
                    </div>
                </div>
            )
        }
    </>
};

export default Homepage;
