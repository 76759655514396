import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const NestedPieChart = ({ ioStats }) => {
    const webLeads = ioStats['Total Web Leads'];
    const otherLeads = ioStats['Total Leads'] - ioStats['Total Web Leads'];

    const webEvents = ioStats['Total Web Events'];
    const autoBookEvents = ioStats['Autobook Total'];
    const otherEvents = ioStats['Total Events'] - ioStats['Total Web Events'] - ioStats['Autobook Total'];

    const data = {
        labels: [],
        datasets: [
            {
                // Outer Pie Chart - Leads
                data: [webLeads, otherLeads],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
                label: 'Leads',
                borderWidth: 1,
                circumference: 360
            },
            {
                // Inner Pie Chart - Events
                data: [webEvents, autoBookEvents, otherEvents],
                backgroundColor: ['#4BC0C0', '#FFCE56', '#36A2EB'],
                hoverBackgroundColor: ['#4BC0C0', '#FFCE56', '#36A2EB'],
                label: 'Events',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        cutoutPercentage: 50,
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        // Determine which dataset is being hovered over
                        const datasetIndex = tooltipItem.datasetIndex;
                        const dataIndex = tooltipItem.dataIndex;

                        // If it's the outer pie chart (Leads)
                        if (datasetIndex === 0) {
                            const labelsOuter = ['Web Leads', 'Other Leads'];
                            return `${labelsOuter[dataIndex]}: ${tooltipItem.raw}`;
                        }

                        // If it's the inner pie chart (Events)
                        if (datasetIndex === 1) {
                            const labelsInner = ['Web Events', 'Autobooks Events', 'Other Events'];
                            return `${labelsInner[dataIndex]}: ${tooltipItem.raw}`;
                        }

                        return '';
                    },
                },
            },
            legend: {
                display: false, // Hide the default legend
            },
        },
        rotation: -90,
        circumference: 360,
    };

    return (
        <div className='ioLeadDistribution-container' >

            <div className='ioLeadsDistribution-data' >
                <p className='ioLeadDistribution-dataObjects'><strong>Web Leads:</strong> {ioStats['Total Web Leads']}</p>
                <p className='ioLeadDistribution-dataObjects'><strong>Web Events:</strong> {ioStats['Total Web Events']}</p>
                <p className='ioLeadDistribution-dataObjects'><strong>Autobook Events:</strong> {autoBookEvents}</p>
                <p className='ioLeadDistribution-dataObjects'><strong>Web Conversion:</strong> {ioStats['Web Conversion Rate']}</p>
                <p className='ioLeadDistribution-dataObjects'><strong>Autobook %:</strong> {ioStats['Autobook %']}</p>
            </div>

            {/*  Nested Pie Chart */}
            <div className='ioLeadDistribution-chart' >
                <Doughnut data={data} options={options} />
            </div>


        </div>
    );
};

export default NestedPieChart;


// #FF7F50 (Coral) - A warm, vibrant accent color.
// #FFD700 (Gold) - A bright, eye-catching shade for highlights.
// #00BFFF (Deep Sky Blue) - A cool and dynamic blue for primary elements.
// #4682B4 (Steel Blue) - A strong and versatile shade for text or secondary elements.
// #2E8B57 (Sea Green) - A fresh, natural green for supporting accents.
