import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

const monthName = ["NaN", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const fetchYearlyWorkflowCount = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(BASE_URL + `/dashboard/location/workflow-count/yearly?locationId=${locationId}&year=2024`, { headers: header });
        return formatWorkflowData(data.workflows);
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch workflow data");
    }
}

const formatWorkflowData = (data) => {
    const workflowCount = [];
    data.forEach(element => {
        const month = monthName[element.month];
        const count = element.activeWorkflowsCount
        workflowCount.push({
            "month": month,
            "count": count
        });
    });
    return workflowCount;
}
