import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';

// Combine slices into one root reducer
const rootReducer = combineReducers({
    auth: authReducer
    // Add other reducers here
});

export default rootReducer;
