import React, { useState } from 'react';
import { getResponseTime } from '../apis/responseTimeApi';
import { notify } from '../utilComponents/Notification';

const ResponseTimeCard = ({ locationId }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responseTimeData, setResponseTimeData] = useState({
        "totalResponseCount": 0,
        "totalResponseTime": 0,
        "avgResponseTime": {
            "days": 0,
            "hours": 0,
            "minutes": 0,
            "seconds": 0
        }
    });

    const handelFetchResponseData = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getResponseTime(startDate, endDate, locationId);
            setResponseTimeData(data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            notify('error', 'Response Time', err.message);
            setLoading(false);
        }
    };

    return (
        <div class="response-time-card">
            <div className="date-range-container">
                <div className="date-inputs">
                    <label htmlFor="start-date">Start Date:</label>
                    <input type="date" id="start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <span className="response-date-arrow">→</span>
                <div className="date-inputs">
                    <label htmlFor="end-date">End Date:</label>
                    <input type="date" id="end-date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </div>

            <button onClick={handelFetchResponseData}>Fetch Data</button>
            
            <div className="response-display">
                <p><strong>Total Response Count:</strong> {responseTimeData.totalResponseCount}</p>
                <div className="response-time-group">
                    <p><strong>Days:</strong> {responseTimeData.avgResponseTime.days}</p>
                    <p><strong>Hours:</strong> {responseTimeData.avgResponseTime.hours}</p>
                    <p><strong>Minutes:</strong> {responseTimeData.avgResponseTime.minutes}</p>
                    <p><strong>Seconds:</strong> {responseTimeData.avgResponseTime.seconds}</p>
                </div>
            </div>

        </div>
    );
};

export default ResponseTimeCard;
