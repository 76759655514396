import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const fetchIoStatsForGivenDates = async (locationId, startDate, endDate) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    const formattedStartDate = convertToYYYYMMDD(startDate);
    const formattedEndDate = convertToYYYYMMDD(endDate);

    try {
        const { data } = await axios.get(BASE_URL + `/dashboard/location/io-stats?locationId=${locationId}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`, { headers: header });
        return data["IO Stats "];
    } catch (error) {
        console.log("ERROR: ", error.response.data.error.message);
        throw new AppError(error.response.data.error.message);
    }
}

export const fetchRentalData = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(BASE_URL + `/dashboard/location/io-rentals?locationId=${locationId}`, { headers: header });
        return data;
    } catch (error) {
        console.log("ERROR: ", error.response.data.error.message);
        throw new AppError('unable to fetch rental data');
    }
}

const convertToYYYYMMDD = (date) => {
    return new Date(date).toISOString().slice(0, 10);
};