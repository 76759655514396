import React, { useState, useEffect } from 'react';
import { fetchYearlySocialMediaDataOfLocation } from '../apis/socialMediaCardApi';
import { notify } from '../utilComponents/Notification';

const SocialMediaCard = ({ locationId }) => {
    const [postCounts, setPostCounts] = useState([{
        "month": "N/A",
        "count": "N/A"
    }]);

    useEffect(() => {
        const getSocialMediaData = async () => {
            try {
                const contactMonthlyCount = await fetchYearlySocialMediaDataOfLocation(locationId); // Fetch contact details from your API
                setPostCounts(contactMonthlyCount.postCount);
            } catch (err) {
                notify('error', 'Social Media Data', err.message);
            }
        };

        getSocialMediaData();
    }, []);

    return (
        <div className="workflow-card-conten">
            <div className="workflow-card-summary">
                Number of posts each month
            </div>
            <div className='workflow-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Post count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {postCounts.map((post) => (
                            <tr key={post.month}>
                                <td>{post.month}</td>
                                <td>{post.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="response-time-group">
                    <p><strong>LinkedIn:</strong> {postCounts.linkedin ? "Yes" : "No"}</p>
                    <p><strong>Facebook:</strong> {postCounts.facebook ? "Yes" : "No"}</p>
                    <p><strong>Tiktok:</strong> {postCounts.tiktok ? "Yes" : "No"}</p>
                    <p><strong>Twitter:</strong> {postCounts.twitter ? "Yes" : "No"}</p>
                </div>
            </div>
        </div>
    );
};

export default SocialMediaCard;
