import React, { useEffect, useState } from 'react';
import { fetchRentalData } from '../apis/ioServiceApi';
import { notify } from '../utilComponents/Notification';

const RentalDetailsCard = ({ locationId }) => {

    const [rentalData, setRentalData] = useState({
        "Rental Item count": "N/A",
        "Popular Items": [
            {
                "Name": "No data",
                "Quantity": "No data",
                "Times Rented": "No data"
            }
        ]
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getRentalData = async () => {
            try {
                const data = await fetchRentalData(locationId);
                setRentalData(data);
            } catch (error) {
                notify('error', 'Rental Data', error.message);
            }

            setIsLoading(false);
        }

        getRentalData();
    }, []);

    if (isLoading) {
        return <div>Locading...</div>
    }

    return (
        <div className="rental-card-content">
            <div className="rental-summary">
                <strong>Total Rental Items:</strong> {rentalData["Rental Item count"]}
            </div>

            <div className="popular-items-table">
                <table>
                    <thead>
                        <tr>
                            <th>Rental Name</th>
                            <th>Quantity</th>
                            <th title="Times Rented">Rented</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rentalData["Popular Items"].map((item, index) => (
                            <tr key={index}>
                                <td>{item.Name}</td>
                                <td>{item.Quantity}</td>
                                <td>{item['Times Rented']}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RentalDetailsCard;
