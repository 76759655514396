import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, loginSuccess, loginFailure } from '../redux/slices/authSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import eventHawkLogo from "../images/eventHawkLogo.png";
import googleLoginIcon from "../images/googleLoginIcon.png";
import { notify } from "../utilComponents/Notification";

const GOOGLE_LOGIN_CLIENTID = "174071612886-ffknen07k4d4g2ckmgrl4h4cgtaja085.apps.googleusercontent.com";

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthenticated, loading, error } = useSelector((state) => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // redirect if the user is already authenticated
    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    // handle email password login
    const handleLogin = async (e) => {
        e.preventDefault();
        dispatch(loginRequest());

        // Simulating login for now, replace with actual API call later
        if (email === 'aayushmaurya10m@gmail.com' && password === 'password') {
            const user = { id: 1, name: 'John Doe', email: 'aayushmaurya10m@gmail.com' };
            dispatch(loginSuccess(user));
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/');
        } else {
            const errorMessage = 'Invalid email or password.';
            dispatch(loginFailure('Invalid email or password.'));
            notify('error', 'Login Failed', errorMessage);
        }
    }

    // Handle google login success
    const handleGoogleSuccess = (response) => {
        const decodedToken = jwtDecode(response.credential); // Google token

        const userEmail = decodedToken.email;
        const userName = decodedToken.name;

        const user = { id: response.credential, name: userName, email: userEmail };

        // Dispatch the token to the Redux store or send it to the backend
        dispatch(loginSuccess(user));
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/');
        notify('success', 'Login Successful', `Welcome back! ${userName}`);
    };

    // Handle Google login failure
    const handleGoogleFailure = () => {
        notify('error', 'Google Login Failed', 'Unable to login with Google. Please try again.');
    };

    return (
        <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENTID}>
            <div className="login-page">
                <div className="login-container">
                    <img src={eventHawkLogo} alt="Event Hawk Logo" className="login-logo" />
                    <h2 className="login-title">Login to Event Hawk Dashboard</h2>
                    {/* <form onSubmit={handleLogin}>
                        <div className="input-group">
                            <label>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                        {error && <p className="error-message">{error}</p>}
                    </form> */}

                    {/* Google Login Button */}
                    <div className="google-login-container">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleFailure}
                            scope="profile email"
                            render={(renderProps) => (
                                <button
                                    className="google-login-button"
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                >
                                    <img src={googleLoginIcon} alt="Google icon" className="google-icon" />
                                    Login with Google
                                </button>
                            )}
                        />
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
}

export default LoginPage;