import React from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import LocationData from './LocationData';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getLocationDetails } from '../apis/locationDetailsApi';
import ReactLoading from 'react-loading';

const LocationPage = () => {

  const [loading, setLoading] = useState(true);

  const [businessDetails, setBusinessDetails] = useState({});

  const { locationId } = useParams();

  useEffect(() => {

    const fetchLocationData = async (locationId) => {
      const locationDetails = await getLocationDetails(locationId);
      setBusinessDetails(locationDetails.location);
      setLoading(false);
    }  

    fetchLocationData(locationId);
  }, []);


  return <>
    {
      loading ? (
        <div className='loadingBar'>
          <ReactLoading type={"bars"} color={"#03fc4e"} height={100} width={100} />
        </div>
      ) : (
        <LocationData locationDetails = {businessDetails}/>
      )
    }
  </>
};

export default LocationPage;