import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";

const BASE_URL = ENDPOINTS.DASHBOARD_BASE_URL;

export const getLocationDetails = async (locationId) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        const url = BASE_URL + `/dashboard/location/business?locationId=${locationId}`;
        const { data } = await axios.get(url, { headers: header });
        console.log(data);
        return data;
    } catch (error) {
        console.log("ERROR: ", error);
        return false
    }
}