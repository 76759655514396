import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './rootReducer';

const persistConfig = {
    key: 'root', // The key for persisted data
    storage, // Default is localStorage
    whitelist: ['auth'] // Optionally only persist specific reducers, here auth
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer, // Use the persist reducer
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development mode
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disable serializability check for redux-persist
        })
});

const persistor = persistStore(store);

export { store, persistor };
