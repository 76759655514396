import axios from "axios";
import AppError from "../utilComponents/ApiError";
import { ENDPOINTS } from "../utilComponents/constants";

export async function requestWorkflowSheet(workflowsList, email) {
    try {
        const { data } = await axios.post(ENDPOINTS.WORKFLOW_MS_BASE_URL + '/filter-workflows', { "filterWorkflowList": workflowsList, "email": email })
        return data;
    } catch (error) {
        throw new AppError('Unable to process your request', 500);
    }
}

export async function checkStatus(jobId) {
    try {
        const { data } = await axios.get(ENDPOINTS.WORKFLOW_MS_BASE_URL + `/check-status?jobId=${jobId}`);
        return data;
    } catch (error) {
        throw new AppError('Unable to fetch the status.', 500);
    }
} 