import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import eventHawkLogo from "./images/eventHawkLogo.png"
import { useDispatch } from 'react-redux';
import { logout } from './redux/slices/authSlice';

const Navbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        // Dispatch the logout action to Redux
        dispatch(logout());

        localStorage.removeItem('user'); // Clear persisted user session
        navigate('/login');
    };

    return (
        <nav className="eh-navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <img src={eventHawkLogo} alt="Event Hawk Logo" />
                </div>
                <div className="navbar-menu">
                    <Link to="/" className="navbar-item">
                        Locations
                    </Link>
                    <Link to="/workflows" className="navbar-item">
                        Workflows
                    </Link>
                </div>
                <div className="navbar-icons">
                    <FaSignOutAlt className="navbar-icon" onClick={handleLogout} />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
