import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// used to displaying the notifications

const Notification = () => {
    return <NotificationContainer />;
};

export const notify = (type, title, message) => {
    switch (type) {
        case 'info':
            NotificationManager.info(message, title);
            break;
        case 'success':
            NotificationManager.success(message, title);
            break;
        case 'warning':
            NotificationManager.warning(message, title, 3000);
            break;
        case 'error':
            NotificationManager.error(message, title, 5000);
            break;
        default:
            NotificationManager.info(message, title);
    }
};

export default Notification;
